:root {
  --dark: #2d3241;
  --dark_trans: #2d3241f1;
  --golden: #ffd600;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  overflow-x: hidden;
}

p,
h1 {
  font-weight: 600;
}

body {
  font-size: 14px;
  background-color: #13161f !important;
  font-family: "Raleway", sans-serif;
  /* background-image: url(./assets/bg.webp); */
  background-image: url(./assets/bg.webp);
  background-size: 100%;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  color: #000 !important;
}

.w-60 {
  width: 60%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 48%;
}

.desktop-header {
  position: fixed !important;
  background-color: #000000dd;
  padding: 0 50px;
  z-index: 9999;
}

.my-nav:hover {
  color: #de811d !important;
}

.params .row {
  padding: 0 2rem;
  gap: 0.3rem;
}

.params {
  font-size: 0.65rem;
  width: 400px;
}
.params img {
  width: 2rem !important;
}

.params .col-md-6 {
  align-items: center;
  /* justify-content: space-evenly; */

  flex: 1 0 33%;
  margin: auto 5px auto;
}

.cas-wrap {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
}

.intro-title {
  margin: 10px 0 20px;
}

.content-container {
  background-color: #181515aa;
  padding: 20px;
}

.tokens {
  position: absolute;
  /* background: url(./assets/tokens.png) no-repeat top; */
  height: 850px;
  transform: scale(0.54);
  width: 100%;
  left: -15%;
  top: -150px;
  z-index: 1;
}

.cas-img {
  position: relative;
  /* background: url(./assets/girls.png) no-repeat top; */
  height: 450px;
  z-index: 2;
  top: -30px;
  transition: transform 300ms ease-in-out;
}

.casino-section {
  position: relative;
  color: #fff;
  margin-top: 12rem;
}

ul {
  /* list-style: none; */
  margin: 0;
  padding: 0;
}

ul svg {
  fill: var(--golden);
  font-weight: bold;
  width: 20px;
  margin-top: -2px;
  margin-right: 3px;
}

.my-card {
  background-color: #000;
  margin: 1rem 0;
  /* color: #000; */
  /* border: 1px solid #00bd68; */
  transition: all 300ms ease-in-out;
  border-radius: 6px;
  /* padding: 0 1rem; */
}

.top-card {
  padding: 1.25rem 0;
  min-height: 150px;
  z-index: 9;
  position: relative;
  width: 100%;
  border: unset !important;
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
  /* color: #113454; */
}

/* .top-card > div {
  min-width: 15%;
  max-width: 28%;
} */

.my-card:hover {
  cursor: pointer;
  box-shadow: 0 0 50px 1px rgba(128, 0, 128, 0.7),
    0 0 10px 2px rgba(147, 112, 219, 0.5), 0 0 10px 2px rgba(186, 85, 211, 0.3),
    0 0 20px 6px rgba(221, 160, 221, 0.1);
  transition: all 300ms ease-in-out;
}

.color-green {
  color: #00bd68;
}
.accordion {
  --bs-accordion-bg: #0000009a;
}

.accordion-button {
  color: #fff !important;
  background-color: #000 !important;
  /* padding: 15px; */
  font-size: 0.9rem;
  /* background-color: var(--dark_trans) !important; */
  --bs-accordion-bg: #000;
}

.accordion-button p {
  width: 90%;
  margin: auto;
}

.accordion-item {
  border: unset;
  margin-bottom: 0.3rem;
}

.accordion-button::after {
  filter: invert(100%);
}

.accordion-body {
  padding-top: 0px;
  padding-bottom: 10px;
}

.accordion-item {
  /* border: 1px solid #00bd68; */
  border-top: unset;
  color: #fff;
  font-size: 0.7em;
}

.accordion-button:not(.collapsed) {
  box-shadow: unset;
}

.accordion-button:focus {
  box-shadow: unset;
  border: unset;
}

.star,
.empty {
  /* color: var(--golden);
   */
  background: linear-gradient(to right, var(--golden) 65%, transparent);
  -webkit-background-clip: text !important;
  color: transparent;
  font-size: 1em;
}

.star::before {
  content: "\2605";
}

.header-btn {
  font-size: 0.75rem;
  padding: 0.75rem 2.3rem;
  background-color: #ff0000;
  border: unset;
}

.header-btn:hover,
.header-btn:active {
  background-color: #ff0000aa !important;
}

.btn {
  font-family: Arial, Helvetica, sans-serif;
}

.top-card .btn {
  font-weight: 600;
  background-color: #9d00ff;
  background-color: #58cd36;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  outline: 0;
  overflow: hidden;
  pointer-events: auto;
  text-transform: uppercase;
  position: relative;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  white-space: nowrap;
  border: 0;
  padding: 1rem 2.75rem;
  font-size: 1rem;
}

.top-card .btn:hover,
.top-card .btn:active {
  background: #9d00ffdd !important;
  background: #58cd36dd !important;
}

.score h3,
.score h5,
.score > div {
  margin: -3px 0;
  line-height: 1.15;
}

.score-text {
  font-weight: 900;
}

/* Hamburger */

#outer-container .flag-logo {
  position: relative;
  right: 58px;
  bottom: 2px;
}
.hamburger-react div {
  background-color: #fff !important;
  border-radius: 5px;
}

.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 22px;
  right: 20px;
  top: 24px;
}

.bm-burger-bars {
  background: #fff;
  border-radius: 6px;
}

.bm-burger-bars:nth-child(2) {
  width: 30px;
  left: unset !important;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  top: 0;
  width: 235px !important;
  height: 65vh !important;
  position: relative;
}

.bm-menu {
  background: #000;
  display: flex;
  justify-content: center;
  padding: 0.5em 0.5em 0;
  border-radius: 25px;
  font-size: 0.9rem;
}

.bm-morph-shape {
  border-radius: 100px !important;

  /* fill: #373a47; */
}

.bm-item-list {
  left: -50px !important;
  color: #b8b7ad;
  padding: 0 0.8em;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.85) !important;
  top: 0;
  left: 0;
}

.nav-menu.open {
  right: 0;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
}

.nav-menu li {
  margin: 21px 0;
}

.nav-menu a {
  text-decoration: none;
  color: #000;
  font-size: 0.75rem;
}

.fs-7 {
  font-size: 0.75rem;
}

.rev-url {
  letter-spacing: 1px;
  margin-top: 4px;
  color: white;
  text-decoration: none;
}

.reg {
  /* padding: 0 1rem 1rem; */
  background-color: #2e2e2eaa;
  line-height: 1.3;
  padding: 10px 120px;
  font-size: 0.68rem;
  color: #fff;
  text-align: center;

  /* background-color: #1134540d; */
  /* color: #000;  */
}

.welcome-bonus {
  width: auto;
  /* padding: 0 1rem; */
  text-align: left;
}

.welcome-bonus h1 {
  font-size: 1.25rem;
  min-width: 200px;
  max-width: 200px;
  font-weight: 700;
  /* text-align: center; */
}

footer {
  /* background-color: #242424; */
  background: linear-gradient(to top, #242424e0 60%, transparent 100%);
  padding: 2.5rem 3rem 3rem;
  color: #fff;
  min-height: 2vh;
}

footer .footer-logo {
  flex-basis: 100%;
}
footer .footer-foo.row {
  --bs-gutter-x: 0;
}
footer .footer-foo {
  flex-basis: 100%;
}

.sticky {
  background-color: #242424e5;
}

a,
a:visited {
  color: #888;
  text-decoration: none;
}

.welcome-bonus a,
.welcome-bonus a:visited {
  color: #113454;
  text-decoration: underline;
}

a:hover {
  color: #fff;
  transition: all 300ms ease-in-out;
}

.disclaimer {
  font-size: 0.75rem;
}
.header {
  display: flex;
  position: absolute;
  top: 0;
  height: 66px;
}

.information {
  background-color: #13161fea !important;
  /* width: 55%; */
  margin: auto;
  padding: 1.25% 5%;
}

/* Rating */

/* Rating item */
.rating {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  width: 3.2rem;
  height: 3.2rem;
  font-size: calc(3rem / 3);
  line-height: 1;
}

/* Rating circle content */
.rating span {
  color: #000;
  position: relative;
  display: flex;
  font-weight: bold;
  z-index: 2;
}

.rating span small {
  font-size: 0.5em;
  font-weight: 900;
  align-self: center;
}

.rating::after {
  content: "";
  position: absolute;
  inset: 0.35rem;
  background: #fff;
  border-radius: inherit;
  z-index: 1;
}

.rating::before {
  content: "";
  position: absolute;
  inset: 10rem;
  border-radius: inherit;
  box-shadow: 0 0 0 1rem #c7e1f3;
  z-index: -1;
}

.rating.good {
  background: #2ecc71;
}

.text-break-all {
  word-break: break-all;
}

@media only screen and (max-width: 1023px) {
  /* .top-card > div {
    min-width: unset;
    max-width: unset;
  } */
  .disclaimer {
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .rating {
    width: 3.7vw;
    height: 3.7vw;
    font-size: calc(3vw / 3);
  }

  .score.d-flex.flex-column div:nth-child(2) {
    font-size: 1vw;
  }

  .casino-main {
    width: 75% !important;
  }
}

@media only screen and (max-width: 320px) {
  .ribbon-2 {
    font-size: 3.5vw;
  }
}

/* TEST */

.my-card-2 {
  width: 250px;
  height: 150px;
  grid-gap: 1rem;
  padding: 2rem 0.5em 1rem;
  transform-origin: 100% 100%;
  color: #fff;
}

.my-card-2 section {
  text-align: center;
  margin-bottom: -1rem;
  background-color: #232325;
  border-bottom: solid 1rem transparent;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 1.5rem),
    50% 100%,
    0 calc(100% - 1.5rem)
  );
}

.my-card-2 button {
  line-height: 0.9;
  border: 0;
  margin: 0.75rem;
  padding: 0.3rem 1.2rem;
  font-size: 1.15rem;
}

.cookie-wrapper {
  padding: 1.5rem 4rem !important;
}

.cookie-wrapper span {
  width: 50%;
}

.cookie-button {
  font-size: 1rem;
  padding: 0.3rem 1.2rem;
}

.cookie-accept {
  background-color: #ffd72a;
  color: #000;
  border: unset;
}

.cookie-reject {
  background: unset !important;
  border: 2px solid #ffd72a;
}
.cookie-accept:hover,
.cookie-accept:focus {
  background-color: #ffd72acc !important;
}
.cookie-reject:hover,
.cookie-reject:focus {
  border: 2px solid #ffd72a4a !important;
}
.ribbon {
  width: 140px;
  height: 140px;
  overflow: hidden;
  z-index: 2;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #de811d;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 255px;
  padding: 2.5px 0;
  background-color: #de811d;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  font-size: 0.75rem;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 45px;
}
.ribbon-top-left::after {
  bottom: 45px;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* Ribbon-2 */

.ribbon-2 {
  /* font-size: 14px; */
  position: absolute;
  display: inline-block;
  margin: 0.45em -0.35em;
  text-align: center;
  color: #000;
  font-weight: bold;
}
.ribbon-text {
  display: inline-block;
  padding: 0.5em 1em;
  min-width: 13em;
  line-height: 1.2em;
  background: #ffd72a;
  position: relative;
}
/* .ribbon-2:after, */
.ribbon-2:before,
.ribbon-text:before,
/* .ribbon-text:after, */
.ribbon-bold:before {
  content: "";
  position: absolute;
  border-style: solid;
}
.ribbon-2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: #ebeced;
  z-index: -2;
}
.ribbon-text:before {
  bottom: 100%;
  left: 0;
  border-width: 0.5em 0.7em 0 0;
  border-color: transparent #fc9544 transparent transparent;
}
.ribbon-text:after {
  top: 100%;
  right: 0;
  border-width: 0.5em 2em 0 0;
  border-color: #fc9544 transparent transparent transparent;
}
.ribbon-2:after,
.ribbon-bold:before {
  top: 0.5em;
  right: -2em;
  border-width: 1.1em 1em 1.1em 3em;
  border-color: #fecc30 transparent #fecc30 #fecc30;
  z-index: -1;
}
.ribbon-bold:before {
  border-color: #ebeced transparent #ebeced #ebeced;
  top: 0.7em;
  right: -2.3em;
}

/* MAC */
@media screen and (min-width: 769px) and (max-width: 1440px) {
  .top-card .btn {
    padding: 0.6rem 2.05rem;
    font-size: 1.25vw;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .params img {
    width: 1.8vw !important;
  }
  .welcome-bonus {
    padding: 0 0.2rem;
  }
  .welcome-bonus h1 {
    font-size: 1.25rem;
    min-width: 120px;
    max-width: 140px;
    font-weight: 700;
    /* text-align: center; */
  }
  .nav {
    gap: 1rem !important;
  }

  .params {
    font-size: 0.55vw;
    width: 30vw;
  }

  .params .row {
    padding: 0 !important;
    gap: 0.3rem !important;
    --bs-gutter-x: 0;
  }

  .params .row > div {
    padding: 0.15rem !important;
  }
  .params .col-md-6 {
    flex: 1 0 39%;
  }
  /* Styles for Desktops */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .ribbon-text {
    min-width: 35vw;
    max-width: 40vw;
  }

  .intro-title {
    font-size: 5vw !important;
    text-align: center;
  }
  .tit-n-des {
    margin: auto;
    /* background-color: var(--dark_trans); */
  }

  .square img {
    width: 40vw !important;
    height: 15vw !important;
  }

  .intro {
    overflow: hidden;
    padding: 0 2vw !important;
  }

  .intro h1 {
    font-size: 1rem;
  }

  .intro p {
    font-size: 0.75rem;
  }

  .my-card {
    margin: 4vw auto;
    position: relative;
    border-radius: 13px;
  }
  .welcome-bonus {
    width: 100%;
    /* margin: 10px 0 0; */
    padding: unset;
  }
  .card-content h1 {
    font-size: 4.25vw;
    text-align: center;
    line-height: 1.25;
    font-weight: bold;
    margin-bottom: 1.8vw;
  }
  .card-content p {
    font-size: 8.5px;
    /* line-height: 1.2; */
  }

  .information {
    width: 95%;
    padding: 5%;
  }
  .my-card {
    padding: 5px;
  }
  .top-card {
    min-height: 50vw;
    padding: 1vw 1.25vw 0.75vw 1.25vw;
    max-height: 400px;
    z-index: 9;
  }
  .card-content {
    display: flex;
    justify-content: space-between;
    /* padding: 1rem 0 1rem 2vw; */
    padding: 1vw 0 1vw 2vw;
    flex-direction: column;
    min-width: 50vw;
    max-height: 100%;
  }
  /*  */

  .top-card .btn {
    font-size: 3vw;
    border-radius: 6px;
    width: 85%;
    width: 100%;
    margin: auto;
    text-transform: uppercase;
    padding: 0.45rem 0rem;
  }
  .fs-7 {
    font-size: 3vw;
  }

  footer {
    padding: 12vw 1rem;
  }

  .sticky {
    padding: 0px 15px !important;
    background-color: #000;
  }
  .sticky div {
    font-size: 2.7vw;
  }
}

@media only screen and (max-width: 767px) {
  body {
    background-image: unset;
  }

  .cookie-wrapper {
    padding: 7vw 8vw !important;
  }

  .cookie-button {
    font-size: 4vw;
  }

  .cookie-wrapper span {
    font-size: 3vw;
    width: 60%;
  }
  .casino-container {
    background-repeat: no-repeat;
    background-image: url(./assets/bg.webp);
    /* background-image: url(./assets/bg-3.jpg); */
    background-position: center 0;
    -webkit-background-position: center 0;
    background-attachment: fixed;
    /* background-color: #000000; */
  }
}
